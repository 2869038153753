.wordmark {
    /* border: 1px solid #ff0000; */
    float: left;    
}

.topmenu {
    font-size: .3em;  
    line-height: .8em;
    padding-top: .1em;
}

.logoChild { 
    /* border: 1px solid blue; */
    display: inline-block;
    vertical-align: middle;    
}

.logoText {
    font-size: 4.6em;
    line-height: .8em;
    vertical-align: middle;
    padding-left: .1em;    
}