/* Menu.css */

/* Fixed positioning for the menu */
.Menu {
    /*
    position: fixed;
    top: 0;
    width: 100%;
    */    
}

.MenuSearch {
    width:100%; 
    margin-top: 10px;
}

/* CSS for menu items */
.MenuWrapper {
    z-index: 1000;
}

.MenuTileWrapper {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.Menu:hover .MenuTileWrapper {
    opacity: 1;
}

/* Show menu items with fade-in effect when wrapper is hovered */
.Menu:hover .MenuTile {
    display: block;    
}