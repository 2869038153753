body {
  margin: 0;
  /* font-family: */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bs-black) !important;
}

.brand {
  font-family: 'Prompt', sans-serif;
  color: #fff;
}

