.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logo-container {
    text-align: center;
}

.logo {
    width: 200px;
    /* Adjust the width as needed */
    height: auto;
}

.coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #696969;    
}

.coming-soon h1 {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 60px;
    line-height: 80%;
}

.coming-soon h3 {
    margin-top: 20px;    
}


.coming-soon p {
    font-size: 18px;
    text-align: center;
}

.were_working {
    margin:20px;
}